<template>
	<div role="progressbar" class="mdc-linear-progress mdc-linear-progress--indeterminate" :aria-label="label" :class="{'mdc-linear-progress--hidden': !visible, 'mdc-linear-progress--animation-ready': visible}">
		<div class="mdc-linear-progress__buffer">
			<div class="mdc-linear-progress__buffer-bar"></div>
			<div class="mdc-linear-progress__buffer-dots"></div>
		</div>
		<div class="mdc-linear-progress__bar mdc-linear-progress__primary-bar">
			<span class="mdc-linear-progress__bar-inner"></span>
		</div>
		<div class="mdc-linear-progress__bar mdc-linear-progress__secondary-bar">
			<span class="mdc-linear-progress__bar-inner"></span>
		</div>
	</div>
</template>

<script>
import { MDCLinearProgress } from '@material/linear-progress'

export default {
	name: 'LoadingLine',
	props: {
		label: {
			type: String,
			default: 'loading',
		},
		visible: true,
		fixed: false,
	},
	data: () => ({
		bar: null,
	}),
	mounted() {
		this.$nextTick(() => {
			this.bar = new MDCLinearProgress(this.$el)
		})
	},
}
</script>

<style scoped lang="scss">
@use "@material/linear-progress";

@include linear-progress.core-styles;

.mdc-linear-progress {
	@include linear-progress.bar-color(modules.color_('secondary'));
	@include linear-progress.buffer-color(rgba(modules.color_('secondary','text'), 0.5));

	position: absolute;
	top: 0;
	left: 0;

	&--hidden {
		opacity: 0;
		pointer-events: none;
		visibility: hidden;
	}
}

</style>
