<template>
	<div class="table-list" :class="{'table-list--loading': loading }">
		<div class="table-list__header" v-if="hasHeader">
			<slot name="header"></slot>
		</div>
		<loading-line class="table-list__loading-line" :visible="loading" />

		<div
			class="table-list__item"
			:class="{ 'table-list__item--no-hover': noHover }"
			:key="key"
			v-for="(item, key) in items"
		>
			<slot name="item" :item="item"></slot>
		</div>

		<div class="table-list__footer">
			<slot name="footer" :items="items" />
		</div>
	</div>
</template>

<script>
import LoadingLine from '@/components/LoadingLine'
import LoadingSpinner from '@/components/LoadingSpinner'

export default {
	name: 'TableList',
	components: {
		LoadingLine,
		LoadingSpinner,
	},
	props: {
		items: {
			type: [Array, Object],
			default: () => ([]),
		},
		loading: Boolean,
		noHover: Boolean,
	},
	computed: {
		hasHeader() {
			return Boolean(this.$slots.header)
		},
		hasFooter() {
			return Boolean(this.$slots.footer)
		},
		pageCount() {
			return this.$store.getters['misc/pageCount']
		},
		listCount() {
			return this.$store.getters['misc/listCount']
		},
	},
}
</script>

<style scoped lang="scss">
.table-list {
	$list: &;
	position: relative;

	&__header {
		@include modules.fontSize(16px);
		background-color: modules.color_('background', 'light');
		font-weight: 400;
		transition: background-color 150ms linear;
	}

	&__item {
		@include modules.fontSize(16px);
		transition: background-color 250ms linear;

		&:hover {
			&:not(#{$list}__item--no-hover) {
				background-color: darken( modules.color_('background', 'light'), 1 );
			}
		}
	}

	&__loader {
		align-items: center;
		background-color: rgba(255, 255, 255, 0.3);
		justify-content: center;
		display: none;
		height: 100%;
		position: absolute;
		top: 80px;
		width: 100%;
		z-index: -1;

		.table-list--loading & {
			display: flex;
			z-index: 2;
		}
	}

	&__loading-line {
		height: 2px;
		opacity: 0;
		pointer-events: none;
		transition: opacity 150ms linear;

		.table-list--loading & {
			opacity: 1;
		}
	}

	&__footer {
		&:not(:empty) {
			@include modules.gutter('padding', 0.5);
			@include modules.gutter('padding-left');
			@include modules.gutter('padding-right');
			color: modules.color_('text', 'medium');
			font-size: 14px;
		}
	}
}
</style>
